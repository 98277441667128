import React, { FC, ReactElement } from "react";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import logo from "./img/plmlogo1.png"
import logo2 from "./img/hlogo.png"
import logo3 from "./img/bplogo.png"
import FacebookIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import EmailIcon from '@mui/icons-material/EmailRounded';
import PolicyIcon from '@mui/icons-material/PolicyRounded';
import footer from './img/footerbackground.jpg';
import LocationOnIcon from '@mui/icons-material/LocationOnRounded';
const handleFacebook = () => {
  window.location.href="https://www.facebook.com/PLM.Haribon";
};

const handleTwitter = () => {
  window.location.href="https://twitter.com/PLM_Manila";
};

const handleInstagram = () => {
  window.location.href="https://www.instagram.com/PLM_Intramuros/";
};

const handleEmail = () => {
  window.location.href="mailto:ithelp@plm.edu.ph?subject=Website Inquiry&body=Hello ithelp";
};

export const Footer: FC = (): ReactElement => {
  return (
    <>
    <Box 
      sx={{
        width: "auto",
        height: "auto",
        backgroundColor: "#A31920",
        // padding: "1rem",
        // pddingTop: "16px",
        backgroundImage: `url("./img/footerbackground.jpg")`,
      }}
    >
      <Grid container height='auto'>
        <Grid item xs={3}>
        <Box sx={{ flexGrow: 0, justifyContent: "flex-start", height: '50px', paddingTop: "16px", paddingLeft: '54px', display: { xs: 'none', md: 'flex' } }}>
          <img src={logo}  alt=""/>
          <img src={logo2} alt=""/>
          <img src={logo3} alt=""/>
        </Box>
        </Grid>
        <Grid item xs={9}>
          <Box sx={{ flexGrow: 0, justifyContent: "flex-end", padding: "16px 48px 0px 0px", width: 'auto',  height: 'auto', display: { xs: 'none', md: 'flex' } }}>
            <FacebookIcon style={{ color: 'white', padding: "0 4px"}} onClick={handleFacebook} />
            <InstagramIcon style={{ color: 'white', padding: "0 4px"}} onClick={handleInstagram} />
            <XIcon style={{ color: 'white', padding: "0 4px" }} onClick={handleTwitter} />
            <EmailIcon style={{ color: 'white', padding: "0 4px" }} onClick={handleEmail}/>
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={3} paddingLeft="54px">
          <Typography color="white" fontSize= "1.5vw">
            PAMANTASAN NG<br/>
            LUNGSOD NG<br/> 
            MAYNILA <br/>
          </Typography>
        </Grid>
        <Grid item xs={4} paddingTop="16px">
          <Button variant="text" sx={{}} startIcon={<LocationOnIcon style={{ color: 'white', padding: "0px", textAlign: "left"}} />}>
            <Typography color="white" variant="body2" fontSize= "1vw">Gen. Luna cor. Muralla Sts., Intramuros, Manila</Typography>
          </Button>
          <br/>
          <Button variant="text" startIcon={<EmailIcon style={{ color: 'white', padding: "0px"}} />}>
            <Typography  color="white" variant="body2" fontSize= "1vw">ithelp@plm.edu.ph</Typography>
          </Button>
          <br/>
          <Button variant="text" startIcon={<PolicyIcon style={{ color: 'white', padding: "0 0 16px 0"}} />}>
            <Typography  color="white" variant="body2" padding= "0 0 16px 0" fontSize= "1vw"><a href="./PrivacyPolicy" style={{ color: "white"  }}>Privacy Policy</a></Typography>    
          </Button>
        </Grid>
        <Grid item xs={5} paddingTop="16px">
          <Box sx={{ flexGrow: 0, justifyContent: "flex-end", height: '50px', paddingTop: '16px', display: { xs: 'none', md: 'flex' } }}>
            <Box sx={{ flexGrow: 0, padding: '0px 16px 0 16px', display: { xs: 'none', md: 'flex' } }}>
              <a href="./Career" style={{ textDecoration: 'none' }}><Typography color="white" width="100%" fontSize= "1vw">Career</Typography></a>
            </Box>
            <Box sx={{ flexGrow: 0, padding: '0px 16px 0 16px', display: { xs: 'none', md: 'flex' } }}>
              <a href="./Bids" style={{ textDecoration: 'none' }}><Typography color="white" width="100%" fontSize= "1vw">Bids and Awards</Typography></a>
            </Box>
            <Box sx={{ flexGrow: 0, padding: '0px 16px 0 16px', display: { xs: 'none', md: 'flex' } }}>
              <a href="./Download" style={{ textDecoration: 'none' }}><Typography color="white" width="100%" fontSize= "1vw">Downloads</Typography></a>
            </Box>
            <Box sx={{ flexGrow: 0, padding: '0px 48px 0 16px', display: { xs: 'none', md: 'flex' } }}>
              <a href="https://plm-opac.follettdestiny.com/" style={{ textDecoration: 'none' }}><Typography color="white" width="100%" fontSize= "1vw">Online Public Access Catalog</Typography></a>
            </Box>
          </Box>
        </Grid>
      </Grid>      
    </Box>
    </>
);
};

export default Footer;