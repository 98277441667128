import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import Box from '@mui/system/Box';
import './App.css';
import Footer from './Footer';
import Header from './Header';
// import InnerBanner from './img/aboutplm.jpg';

function createData(
  title: string,
  link: string,
) {
  return { title, link };
}


const row2 = [
  createData('I. Manuals and resource materials', ''),
  createData("Student manual", "./images/PLM_Student_Manual_v1.pdf"),
  createData('PLM Suicide Prevention and Postvention Protocol: A Guide for the School Community', 'https://archive.plm.edu.ph/downloads/OGTS/PLM_Suicide_Protocol.pdf'),
  createData('University Health Services Orientation for Scholars', 'https://archive.plm.edu.ph/downloads/University%20Health%20Services%20Orientation%20for%20Scholars.pdf'),
  createData('Professionalization Program for Public Procurement Practitioners brochure', 'https://archive.plm.edu.ph/downloads/BROCHURE_PROCUREMENT_TRAINING.pdf'),
  createData('', ''),
  createData('II. Forms for students', ''),
  createData('Scholarship application form (revised 2019)', '"https://archive.plm.edu.ph/downloads/OVPPA/Scholarships/Scholarship_Application_Form_2019.pdf'),
  createData('DOH Medical Scholarship form', 'https://archive.plm.edu.ph/downloads/OVPPA/Scholarships/Scholarship_Application_Form_2019.pdf'),
  createData('DOH Scholar Commitment form', 'https://www.plm.edu.ph/images/downloads/Apply/DOH_Scholars_Commitment_Form.pdf'),
  createData('Data Privacy consent form', 'https://www.plm.edu.ph/images/downloads/our/PLM-Data-Privacy-Consent-form-web-v2.jpg'),
  createData('Request Form for School Credentials', 'https://archive.plm.edu.ph/downloads/OUR/OUR%20Request%20Form.pdf'),
  createData('Checklist of Requirements for Request for School Credentials', 'https://archive.plm.edu.ph/downloads/OUR/OUR%20Checklist%20of%20Requirements.pdf'),
  createData('Philhealth members registration form (PMRF, January 2020 version)', 'https://www.plm.edu.ph/images/downloads/Apply/Philhealth_PMRF_012020.pdf'),
  createData('', ''),
  createData('III. Forms for faculty and administrative workers', ''),
  createData('Guidelines for applicants and new hires', 'https://plmedu-my.sharepoint.com/:w:/g/personal/mrcclagman_plm_edu_ph/EQeN33LfxflLg2cP6az7I6ABIUYQbO9wM3wPQyVb0Hzi9w'),
  createData('CSC Reso No. 1700656', 'https://archive.plm.edu.ph/downloads/HRDO/2017_PDS/CSC_Res_No._1700656_(PDS).png'),
  createData('Personal Data Sheet (CS Form 212)', 'https://plmedu-my.sharepoint.com/:x:/g/personal/mrcclagman_plm_edu_ph/EcEyC1ylEtlLqQgUZwQdGq4BPk3ybhQA3gK5qIrOfDnQLQ?e=ejCY3M'),
  createData('Personal Data Sheet (CS Form 212) Attachment - Work Experience Sheet', 'https://plmedu-my.sharepoint.com/:w:/g/personal/mrcclagman_plm_edu_ph/EVxJNsySb_ZNnGSVtsZ5QN0Bu0N1W6rUtIMZ6UsG7avcyQ?e=2vdcva'),
  createData('Personal Data Sheet (CS Form 212) Attachment - Revised Guide to Filling Up the Personal Data Sheet', 'https://archive.plm.edu.ph/downloads/HRDO/2017_PDS/CS_Form_No._212_Revised_Personal_Data_Sheet.xlsx'),
  createData('Philhealth Membership Registration Form (PMRF)', 'https://plmedu-my.sharepoint.com/:b:/g/personal/mrcclagman_plm_edu_ph/ES9WHNoazuFPsbA-0wwLnHQB6xL6YZ2Ps-iihbgm-D1ugQ?e=ICiCqe'),
  createData('GSIS Membership Information Sheet (MIF)', 'https://plmedu-my.sharepoint.com/:b:/g/personal/mrcclagman_plm_edu_ph/EQYEQpDCtdRJqY7K7Dn2118BmLMUiR0D0Vyb_goT2twXcA?e=wGBIxU'),
  createData("PAGIBIG Member's Data Form (MDF)", 'https://plmedu-my.sharepoint.com/:b:/g/personal/mrcclagman_plm_edu_ph/EbJuZBh2c5FDkW0Jgty6TAsB7ky8laxrfTb7vREZCr8hug?e=gPRZ2R'),
  createData('Statement of Assets, Liabilities, and Net Worth (SALN) form', 'https://plmedu-my.sharepoint.com/:w:/g/personal/mrcclagman_plm_edu_ph/EXDHiAQSfdBKp3lnY889nqoBeNYkCzmmKkW9egW1yI_aXw?e=QJ43Us'),
  createData('Daily Time Record (DTR)', 'https://plmedu-my.sharepoint.com/:x:/g/personal/mrcclagman_plm_edu_ph/ESGN9VbS-r1PlLOdfHVsvxsBgWx3uUwr1M00c2LjUA0ODQ?e=u1qN8Z'),
  createData('Certification of Assumption to Duty', 'https://plmedu-my.sharepoint.com/:w:/g/personal/mrcclagman_plm_edu_ph/EQXAKm2PyCpMscZJfOJRhSIBoHs6CUfCJCaULxMVBPHRyw?e=DCBY9h'),
  createData('Service Report (for administrative COS workers)', 'https://plmedu-my.sharepoint.com/:w:/g/personal/mrcclagman_plm_edu_ph/EaC-dmSoaP9MtRVJZQ5AbHMB2kjVnqzvFFPD8maT4raPsg?e=H9WwRw'),
  createData('Service Report (for faculty)', 'https://plmedu-my.sharepoint.com/:w:/g/personal/mrcclagman_plm_edu_ph/ETMNW3HGhCNHmeXoJkhTqlgBrWNxcYt4OUIYIT5_7nvA6g?e=E48wKr'),
  createData('Staffing Pattern Form', 'https://archive.plm.edu.ph/downloads/HRDO/Staffing_Pattern_Form_NameofOffice.xlsx'),
  createData('Coaching Journal', 'https://archive.plm.edu.ph/downloads/HRDO/SPMS_Forms_2016/Coaching%20Journal%20Template%20v.2016.docx'),
  createData('Individual Performance Contract (IPC) Targets', 'https://archive.plm.edu.ph/downloads/HRDO/SPMS_Forms_2016/IPCR%20Targets%20v.2016.docx'),
  createData('Individual Performance Contract Review (IPCR) Form', 'https://archive.plm.edu.ph/downloads/HRDO/SPMS_Forms_2016/IPCR_Form%20v.2016.docx'),
  createData('Office Performance Contract (OPC) Targets', 'https://archive.plm.edu.ph/downloads/HRDO/SPMS_Forms_2016/OPCR%20v.2016.docx'),
  createData('Office Performance Contract Review Form', 'https://archive.plm.edu.ph/downloads/HRDO/SPMS_Forms_2016/OPCR%20v.2016.docx'),
  createData('Job Description Form', 'https://archive.plm.edu.ph/downloads/HRDO/Job%20Description%20Form.docx'),
  createData('Statement of Duties and Responsibilities', 'https://archive.plm.edu.ph/downloads/HRDO/Statement%20of%20Duties%20and%20Responsibilities.xls'),
  createData('Performance Evaluation System Form', 'https://archive.plm.edu.ph/downloads/HRDO/PES%20Form.xls'),
  createData('Download PLM Mobile App', 'https://plmedu.sharepoint.com/:u:/s/MobileAppDev/EesWXDgoVmJIhdWCTzgeAbYBNifIDOthOVWZqnZK15hsRA?e=0bfmcb'),
];

const Download = () => {
  return (
    <div>
      <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "center", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%" alt="" /> */}
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '1vw', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '1vw 5vw', height: '1vw', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">Downloads</Typography>
      </Box>
      <br/>
      <TableContainer component={Paper} sx={{paddingLeft: "20%"}}>
      <Table sx={{ minWidth: 650, maxWidth: "50%" }} aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="center"><Typography variant="h5">Documents</Typography></TableCell>
            <TableCell align="center"><Typography variant="h5">Link</Typography></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {row2.map((row) => (
            <TableRow
              key={row.title}
              sx={{ border: 0 }}
            >
              <TableCell component="th" scope="row" align="left">
                {row.title}
              </TableCell>
              <TableCell align="center"><Typography><a href={row.link != '' ? row.link : ''}>{row.link != '' ? 'Download' : ''}</a></Typography></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>
      <br/>
      <br/>
      <br/>
      <Footer/>
    </div>
  );
}

export default Download;
